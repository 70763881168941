.providerCard {
  padding: 0.75rem 1.25rem;
}
.profileCard {
  border: 1px solid rgba(0,0,0,.125);
}

.profileWrapper {
  display: flex;
}

.imageWrapper {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.profileContent {
  width: calc(100% - 90px);
  display: flex;
  justify-content: space-between;
}

.profileData {
  width: 100%;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.availablityDate {
  padding-right: 12px;
}

@media screen and (max-width: 768px) {
  .providerCard {
    padding: 0.625rem 0;
  }
  .profileContent {
    flex-direction: column;
  }
  .btnWrapper {
    justify-content: flex-start;
  }
}
