.my-appointments .nav-tabs .nav-link.active {
    background: #1ca3d8;
    border-color: #22b8f3;
    color: rgb(36, 36, 36);
    border-radius: 5px;
    border: 0.5 solid;
}


.my-appointments .nav-tabs .nav-link {
    cursor: pointer;
}

.chiefcomplaint {
    max-height: 22px;
    min-width: 180px;
    min-width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis !important;
}

.empty-content {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .2rem !important;
    box-shadow: 0 2px 4px rgba(126, 142, 177, .12);
    padding: 0.75rem 1.25rem;
    text-align: center;
    width: 100%;
}

.ham-menu:first-child {
    color: white;
    width: 20px;
    height: 20px;
}

.card-tooltip {
    min-width: auto;
    max-width: 400px;
    font-weight: 500;
}
.my-appointments .tab-content{
    overflow: scroll;
    max-height: 100vh;
}

.my-appointments .tab-content::-webkit-scrollbar {
    display: none !important;
}

.manage-file-card-container {
    height: 50vh;
    overflow-y: scroll;
}

.manage-file-upload-container {
    display: flex;
    align-items: center;
    border-radius: 3px;
    background-color: #F5F5F5;
    height: 5rem;
    padding: 1.25em 1em 1em 1em;
    margin-top: 1em;
}

.manage-file-upload {
    background-color: #143A45;
    color: #fff;
    border-style: solid;
    border-radius: 5px;
    border-color: #143A45;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    margin-right: 1em;
  }

  .manage-file-modal-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;
  }

  .refund-modal-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50vh;
  }

  .refund-coupon-modal-spinner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 244px;
  }

  .file-related-info {
      display: flex;
      flex-direction: column;
  }

  .file-related-info i {
    color: grey;
  }

  .manage-file-modal-footer {
      justify-content: center;
  }

  .reason-for-cancellation {
      min-height: 100px;
      max-height: 150px;
  }

  .centralize {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .refund-modal-body {
      overflow: scroll;
      min-height: auto;
      max-height: 65vh;
  }

  .consultation-card-buttons {
      white-space: nowrap;
      /* min-width: 100px; */
      max-width: auto;
      overflow: hidden;
      text-overflow: ellipsis;
  }

  .appointment-cancelled-text {
      text-align: center;
  }

  .rule-legend {
      background-color: #F7D159;
      height: 1em;
      width: 1em;
      margin-bottom: 1em;
  }

  .rule-legend-text {
    margin: 0 .5em 1em;
  }

  .legend-container {
      display: flex;
      align-items: center;
  }