.card-body {
  padding: 0.75rem 1.25rem;
}
.health-info-collapse {
  padding: 15px 0;
}
.health-profile-alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  /* color: #495057; */
  background-color: #f1f1f1;
  border-color: #d6d8db;
}

.health-info-header-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.health-info-header {
  margin-left: 30px;
  margin-bottom: 0;
  font-weight: 500;
  font-size: 20px;
}

.input-group-container {
  display: flex;
}

.sidebar-brand {
  background-color: white;
}

.sidebar-brand:hover {
  background-color: white;
}
.vitals_info_form p.down_label {
  text-align: center;
  margin-top: -15px;
  font-size: 12px;
  margin-bottom: 0px;
  color: steelblue;
}
.vitals_info_form ,.allergy_info,.medication_info {
  display: inline-block;
  margin-bottom: 3px;
  text-align: left!important;
}
.vitals_info_form {
  text-align: left;
}
.pull-right {
  float: right;
}
.react-select-container .react-select__value-container {
  height: inherit !important;
}
.react-datepicker__input-container input {
  display: block;
  width: 230px !important;
  height: calc(1.90625rem + 2px);
  padding: 0.25rem 0.7rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da !important;
  border-radius: 0.2rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

input {
  max-width: 100%;
}
.HealthInfo{
  height: 100vh;
    overflow: scroll;
}
.HealthInfo::-webkit-scrollbar {
  display: none;
}
.HealthInfo .table th,
.HealthInfo .table td {
  padding: 5px;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}
.VitalsInfo {
  position: relative;
}
.VitalsTableExpands span {
  background: #e8f5ef;
    display: inline-block;
    padding: 6px 10px;
    line-height: 12px;
    font-size: 12px;
    color: #116f45;
    margin-right: 10px;
    height: 24px;
    margin-top: 10px;
    min-width: 30%;
    border-radius: 3px;
}
.HealthInfo .spinner-border {
  position: absolute;
  left: 45%;
  top: 45%;
  z-index: 99;
}
.HealthInfo .react-bootstrap-table .row-expansion-style {
  padding: 8px;
  padding-top: 2px;
}
.badge {
  cursor: pointer;
}
.modal-content label{
  font-size: 12px;
}
.HealthInfo .modal-body{
  margin: 0px!important;
}
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.bordered  .react-datepicker__input-container input, .react-datepicker__input-container:focus input, .input-wrapper .form-control, .input-wrapper .form-control:focus, .custom-select {
  border-color: #ced4da!important;
}
.RecordDate.form-group label{
  width: 15%;
}
.RecordDate.form-group .react-datepicker-wrapper{
  width: 50%;
    display: inline-block;
}
.health-info-header-container button{
  position: absolute;
  right: 20px;
}
span.requiredcustom{
  font-size: 10px;
  color: red;
  display: block;
}
.health-info-header{
  cursor: pointer;
}
table svg.mr-2 {
  margin-right: 0px!important;
}
.outline-primary {
  border-color: #22b8f3;

  color: #0798d1;

  background-color: #fff;
}
.outline-primary:hover {
  background-color: #04a4e4f3;
    border-color: #22b8f3;
  color: #fff;
}
.delete-table-row{
  margin-bottom: 0px;
    padding: 0.2rem 0.7rem;
}
.VitalsModal,.AllergyModal,.MedicationModal,.PharmaciesModal{
  margin-top: 0px!important;
}
.ModalHeader{
  text-align: center;
  margin-bottom: 15px;
}

.pharmacy_favourite{
  cursor: pointer;
  font-size: 24px;
}

.health-card.vital-table {
  padding-top: 0;
}

.schedule-header-carousel .rec.rec-carousel{
  height: 60px !important;
  margin-bottom: 15px;
}

.health-card.vital-table .schedule-header {
  margin: -4px;
}

.schedule-body-carousel .rec.rec-item-wrapper {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.schedule-header-carousel .rec.rec-slider-container, .schedule-body-carousel .rec.rec-slider-container {
  margin: 0;
}

.schedule-body-carousel .pat-time-slot__items {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.schedule-body-carousel .pat-time-slot__item-vital {
  color: black;
}

.schedule-body-carousel .pat-vital-slot, .schedule-body-carousel .pat-time-slot__items, .schedule-body-carousel .pat-time-slot, .schedule-body-carousel .pat-time-slot__item-vital-data {
  width: 100%;
}

.health-card.vital-table .profile-row {
  border-bottom: none;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 768px) {
  .health-card {
    overflow-x: scroll;
  }
  .health-card .react-bootstrap-table {
    width: 676px;
  }

  .health-card .react-bootstrap-table .delete-table-row {
    margin-bottom: 0px;
    padding: 0.2rem 6px;
  }

  .health-profile-btn {
    width: 138px!important;
    padding: 2px!important;
  }

  .health-profile-btn svg {
    float: left!important;
    margin-top: 4px;
    margin-left: 3px;
    margin-right: 0!important;
  }
  
}
.save-condition-btn{

  margin-left: 45%;
}

.pharmacy_edit_button{
  height: 30px;
    line-height: 10px;
}

@media screen and (max-width: 390px) {
  .health-info-header {
    margin-left: 10px;
  }

  .health-info-header-container .btn {
    width: 137px;
    padding: 2px 2px;
    align-items: center;
    justify-content: center;
  }
}

.vitals-table-scroller {
  color: grey;
  border-color: grey;
}

.vitals-table-scroller:hover {
  color: white !important;
  background-color: #46b7ee !important;
  border-color: #46b7ee;
}

.vitals-table-scroller:not(:disabled):not(.disabled):active:focus,
.vitals-table-scroller:focus {
  box-shadow: none;
}

.justify-flex-end {
  justify-content: flex-end;
}

.medication-exclamation {
  margin: 1em;
  color: #00a1fa;
}

.doc-upload-tooltip {
  max-width: 25vw;
  font-size: 1em;
  font-weight: 500;
  border-radius: 0.25em;
  z-index: 100;
}
.conditions-drop-down{
  z-index: 100;
}

.vital-modal-close{
  cursor: pointer;
  position: absolute;
    right: 5px;
    top: -3px;
    color: #46b7ee;
    z-index: 99;
    font-size: 24px;
}
.modal-border {
  border-bottom: 1px solid #dee2e6;
}