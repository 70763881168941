.header-center{
    display: flex !important;
    margin-left: 196px;
  }

.darker{

    color: #808080;

}

.upload-modal-close{
  cursor: pointer;
  position: absolute;
    right: 5px;
    top: -3px;
    color: #46b7ee;
    z-index: 99;
    font-size: 24px;
}
.modal-border {
  border-bottom: 1px solid #dee2e6;
}
.font-style {
  font-size:larger;
}
.ModalHeader{
  text-align: center;
 /*  margin-bottom: 15px; */
  
}
