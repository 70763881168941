.documents .tab .nav-tabs .nav-link {
  cursor: pointer;
}
.documents .tab .nav-tabs .nav-link.active {
  background: #203a45;
  color: #fff;
  border-radius: 5px;
}
.Identification {
  font-family: "PT Sans";
}
.tree-column {
  height: 70vh;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid #eaeaea;
}

.tree-column h4 {
  margin: 10px;
  display: inline-block;
}
.folder--label.selected {
  width: max-content;
  margin: 1px;
  padding: 0px 10px;
  color: #fff;
  border-radius: 5px;
  background: #203a45;
}
.container.uploader {
  padding: 0px;
  cursor: pointer;
}
.uploader .dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}
.dropzone p {
  margin-bottom: 0px;
}
.nodatafound {
  background: #f5f4f4;
  text-align: center;
  padding: 10px;
  border: 1px solid #eaeaea;
  margin-top: 30px;
}
.file-preview {
  height: 70vh;
  width: 100%;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  overflow: scroll;
  text-align: center;
}

.file-preview::-webkit-scrollbar-thumb {
  background-color: #46b7ee;
  outline: 1px solid slategrey;
}

.file-preview::-webkit-scrollbar {
  width: 1em;
}

.file-preview p {
  margin-bottom: 0px;
}

.doc-type-image{
  position: absolute;
    top: 50%;
    height: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
}
.react-pdf__Page__canvas{
width: 100%!important;
}

.preview-buttons {
  text-align: center;
  margin-top: 10px;
}
.preview-buttons button {
  margin-bottom: 0px;
}

.tree-column svg:not(:root).svg-inline--fa {
  color: #46b7ee;
}
.tree-column button svg:not(:root).svg-inline--fa {
  color: #fff;
}
.document-title {
  display: inline-block;
}
@media (max-width: 767px) {
  .tree-column {
    height: 100%;
  }
  .mobile-mb-15 {
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .mobile-nav {
    flex-direction: column;
  }

  .mobile-nav .nav-item {
    display: flex;
  }
}