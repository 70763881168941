.my-device-flex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
}
.my-device-addnew-modal-footer{
    justify-content: center !important;
}
.my-device{
    background-color: #fff;
    padding: 10px 10px 25px;
    margin: 5px;
    -webkit-box-shadow: 0px 4px 6px 1px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 4px 6px 1px rgba(230, 230, 230, 1);
    box-shadow: 0px 4px 6px 1px rgba(230, 230, 230, 1);
    border-radius: 5px;
}

.device-card-wrap {
    position: relative;
}

.device-card-wrap .badge {
    position: absolute;
    right: 10px;
    font-size: 14px;
    cursor: auto;
}

.mydevice-plus-icon {
    cursor: pointer;
    float: right;
    padding: 2px 7px;
    font-size: 10px;
    background: #cd8217;
    border-radius: 20px;
    color: #fff;
}
.my-devices-action-btns{
    cursor: pointer;
    margin: 5px 10px;
}
.my-devices-table{
    background-color: #fff;
    padding: 20px 10px 0px;
    margin: 5px;
    -webkit-box-shadow: 0px 4px 6px 1px rgba(230, 230, 230, 1);
    -moz-box-shadow: 0px 4px 6px 1px rgba(230, 230, 230, 1);
    box-shadow: 0px 4px 6px 1px rgba(230, 230, 230, 1);
    border-radius: 5px;
}
.add-new-device .modal-title{
    font-size: 19px;
}
.add-new-device ::placeholder{
    font-size: 15px !important;
}
.my-device-logos{
    width: 30px;
    object-fit: contain;
}