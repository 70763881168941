.opentok-card-body {
    background-color: #1b3b44;
    color: #ffffff;
    border-radius: 0.25rem;
    height: 78.8vh;
  }
  
  .button-container {
    /* background-color: rgba(255, 255, 255, 0.5); */
    bottom: 5vh;
    margin-left: 3vw;
    position: absolute;
    z-index: 100;
    border-radius: 5px;
    padding: 0.5em;
    width: 94%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
        align-content: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  .button-container button {
    margin: auto 1em;
    width: 5vw;
    height: 5.5vh;
    font-size: larger;
  }
  
  .button-container button:active {
    color: #39e2cc !important;
  }
  
  .active-button {
    background-color: whitesmoke;
    color: #1d3b44;
    -webkit-transition: 200ms ease;
    transition: 200ms ease;
  }
  
  .active-button:hover {
    background-color: white;
    color: #1d3b44 !important;
  }
  
  #videos {
    /* background-color: khaki; */
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  #subscriber {
    /* background-color: lightcoral; */
    margin: auto;
    width: 98%;
    height: 60vh;
    overflow-y: auto;
    border-radius: 0.25rem;
    /* BLANK STATE TEXT  */
    text-align: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.5em;
    font-weight: 500;
    color: #4d7b83;
    /* GRID SETTINGS  */
    display: -ms-grid;
    display: grid;
    grid-auto-flow: column;
    gap: 1%;
    -ms-grid-columns: (1fr)[auto-fill];
        grid-template-columns: repeat(auto-fill, 1fr);
  }
  
  #publisher {
    /* background-color: lightseagreen; */
    margin: auto;
    width: 11vw;
    height: 14vh;
    border-radius: 0.25rem;
    margin-bottom: 0.5em;
  }
  
  .margin-left {
    margin-left: .5em;
  }
  
  .margin-right {
    margin-right: 1em;
  }
  
  .margin-top {
    margin-top: 1em;
  }
  
  .video-call-spinner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  
  @-webkit-keyframes fadein {
    from {
      opacity: 50%;
    }
    to {
      opacity: 100%;
    }
  }
  
  @keyframes fadein {
    from {
      opacity: 50%;
    }
    to {
      opacity: 100%;
    }
  }
  
  /* I-PHONE 5 SCREEN SETTINGS  */
  @media screen and (min-width: 320px) and (max-width: 349px) {
    .video-call-heading {
      font-size: 1.2em;
    }
    .video-card-height {
      padding-top: 5px !important;
      min-height: 71vh !important;
    }
    .opentok-card-body {
      background-color: #1b3b44;
      color: #ffffff;
      border-radius: 0.25rem;
      height: 68vh;
      width: 80vw;
      margin: -4% -5%;
    }
    #publisher {
      /* background-color: lightseagreen; */
      margin: auto;
      width: 35vw;
      height: 15vh;
      border-radius: 0.25rem;
      margin-bottom: 0.5em;
    }
    #subscriber {
      /* background-color: lightcoral; */
      margin: auto;
      width: 90vw;
      height: 48.5vh;
      overflow-y: auto;
      border-radius: 0.25rem;
      /* BLANK STATE TEXT  */
      text-align: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 1.5em;
      font-weight: 500;
      color: #4d7b83;
      /* GRID SETTINGS  */
      display: -ms-grid;
      display: grid;
      grid-auto-flow: column;
      gap: 1%;
      -ms-grid-columns: (1fr)[auto-fill];
          grid-template-columns: repeat(auto-fill, 1fr);
    }
    .button-container {
      /* background-color: rgba(255, 255, 255, 0.5); */
      bottom: 1vh;
      margin-left: 5%;
      z-index: 100;
      width: 100%;
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .button-container button {
      min-height: 8vh !important;
      min-width: 8vh !important;
      max-height: 8vh !important;
      max-width: 8vh !important;
      margin: 0 4px !important;
    }
    .please-wait-message {
      font-size: 17px;
    }
  }
  
  /* I-PHONE X AND GENERIC ANDRIOD SCREEN SETTINGS  */
  @media screen and (min-width: 350px) and (max-width: 399px) {
    .video-call-heading {
      font-size: 1.2em;
    }
    .video-card-height {
      padding-top: 5px !important;
      min-height: 75vh !important;
    }
    .opentok-card-body {
      background-color: #1b3b44;
      color: #ffffff;
      border-radius: 0.25rem;
      height: 73vh;
      width: 82vw;
      margin: -4% -4%;
    }
    #publisher {
      /* background-color: lightseagreen; */
      margin: auto;
      width: 35vw;
      height: 15vh;
      border-radius: 0.25rem;
      margin-bottom: 0.5em;
    }
    #subscriber {
      /* background-color: lightcoral; */
      margin: auto;
      width: 90vw;
      height: 54vh;
      overflow-y: auto;
      border-radius: 0.25rem;
      /* BLANK STATE TEXT  */
      text-align: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 1.5em;
      font-weight: 500;
      color: #4d7b83;
      /* GRID SETTINGS  */
      display: -ms-grid;
      display: grid;
      grid-auto-flow: column;
      gap: 1%;
      -ms-grid-columns: (1fr)[auto-fill];
          grid-template-columns: repeat(auto-fill, 1fr);
    }
    .button-container {
      /* background-color: rgba(255, 255, 255, 0.5); */
      bottom: 1vh;
      margin-left: 5%;
      z-index: 100;
      width: 100%;
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .button-container button {
      min-height: 8vh !important;
      min-width: 8vh !important;
      max-height: 8vh !important;
      max-width: 8vh !important;
      margin: 0 4px !important;
    }
    .please-wait-message {
      font-size: 17px;
    }
  }
  
  /* I-PHONE 6-8 SCREEN SETTINGS  */
  @media screen and (min-width: 400px) and (max-width: 480px) {
    .video-call-heading {
      font-size: 1.4em;
    }
    .video-card-height {
      padding-top: 5px !important;
      min-height: 76.5vh !important;
    }
    .opentok-card-body {
      background-color: #1b3b44;
      color: #ffffff;
      border-radius: 0.25rem;
      height: 74vh;
      width: 85vw;
      margin: -3% -4%;
    }
    #publisher {
      /* background-color: lightseagreen; */
      margin: auto;
      width: 35vw;
      height: 15vh;
      border-radius: 0.25rem;
      margin-bottom: 0.5em;
    }
    #subscriber {
      /* background-color: lightcoral; */
      margin: auto;
      width: 90vw;
      height: 55.4vh;
      overflow-y: auto;
      border-radius: 0.25rem;
      /* BLANK STATE TEXT  */
      text-align: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 1.5em;
      font-weight: 500;
      color: #4d7b83;
      /* GRID SETTINGS  */
      display: -ms-grid;
      display: grid;
      grid-auto-flow: column;
      gap: 1%;
      -ms-grid-columns: (1fr)[auto-fill];
          grid-template-columns: repeat(auto-fill, 1fr);
    }
    .button-container {
      /* background-color: rgba(255, 255, 255, 0.5); */
      bottom: 1vh;
      margin-left: 5%;
      z-index: 100;
      width: 100%;
      position: absolute;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
    }
    .button-container button {
      min-height: 8vh !important;
      min-width: 8vh !important;
      max-height: 8vh !important;
      max-width: 8vh !important;
      margin: 0 4px !important;
    }
    .please-wait-message {
      font-size: 17px;
    }
  }
  
  /* TABLE DEVICE SCREEN SETTINGS   */
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    #publisher {
      /* background-color: lightseagreen; */
      margin: auto;
      width: 25vw;
      height: 12vh;
      border-radius: 0.25rem;
      margin-bottom: 0.5em;
    }
    #subscriber {
      /* background-color: lightcoral; */
      margin: auto;
      width: 85vw;
      height: 63.5vh;
      overflow-y: auto;
      border-radius: 0.25rem;
      /* BLANK STATE TEXT  */
      text-align: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: 1.5em;
      font-weight: 500;
      color: #4d7b83;
      /* GRID SETTINGS  */
      display: -ms-grid;
      display: grid;
      grid-auto-flow: column;
      gap: 1%;
      -ms-grid-columns: (1fr)[auto-fill];
          grid-template-columns: repeat(auto-fill, 1fr);
    }
    .button-container {
      bottom: 5vh;
      position: absolute;
      z-index: 100;
      padding: 0.5em;
      width: 80%;
      margin-left: 7%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-line-pack: center;
          align-content: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
    }
    .button-container button {
      margin: auto 0.5em !important;
      width: 10vw;
      height: auto;
      padding: 0.5em;
      font-size: large;
    }
  }
  
  /* HIDES SCREEN SHARE BUTTON FROM MOBILE DEVICES  */
  @media only screen and (min-width: 320px) and (max-width: 900px) {
    .hide-on-phones {
      display: none;
    }
  }