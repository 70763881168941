.profile-name {
    font-size: 15px;
    font-weight: 500;
    color: #203A45;
    margin-top: 18px;
}
.profile-img {
    display: flex;
}
.profile-card {
    display: flex;
    justify-content: center;
    width: 250px;
}
.profile-main {
    margin-top: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
    color: #203A45;
    margin-bottom: 0;
}
.dependent-list {
    font-size: 15px;
    color: #203A45;
    margin-bottom: 0;
}
.relation-text {
    font-size: 12px;
}
.flex {
    display: flex;
}
.parent {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: 500;
}
.view-profile {
    padding-left: 1rem;
    font-size: 14px;
}
.signout {
    padding-right: 1rem;
    font-size: 14px;
    color: red;
}
.navbar-right-icon {
    display: flex;
    justify-content: space-around;
    position: absolute;
    right: 3.7em;
    top: .5em;
}
