.disabled {
  color: #ddd;
}

.startRating_value {
  color: #6c757d;
  margin-left: 5px;
}

.bookingSummary_docDetails_wrap {
  padding: 0 0 1rem 0;
}

.bookingSummary_docDetails {
  margin-left: 0.5rem;
  padding-top: 12px;
}

.bookingSummary_docDetails_img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 12px;
}

.bookingSummary_docDetails_img > img {
  width: 122px;
  height: 122px;
}

.bookingSummary_location {
  margin-top: 0.5rem;
}

.bookingSummary_finalPrice {
  font-size: 1.1em;
  font-weight: bold;
}

.bookingSummary_pricedetails {
  margin: 0 auto;
  max-width: 400px;
}

.bookingSummary_pricedetails .table {
  margin-bottom: 0.5rem;
}

.bookingSummary_pricedetails td {
  padding: 0.35rem;
}

.applyCoupon-modalButton {
  color: #46b7ee;
  cursor: pointer;
  margin-bottom: 20px;
}

.applyCoupon-modalButton span:hover {
  color: #30a671;
  text-decoration: underline;
}

.applyCoupon_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.icon-right-spacing {
  margin-right: 5px;
}

.applyCoupon_list h5 {
  margin: 11px 0 10px;
  font-size: 14px;
}

.applyCoupon_list li {
  border-width: 0;
}

.applyCoupon_item.selected {
  color: #588209;
}

.applyCoupon_item:hover {
  color: #588209;
  cursor: pointer;
}

.payment-block {
  margin-top: 18px;
  border: 1px solid #e4e4e4;
  border-top: 3px solid #e4e4e4;
  padding: 15px;
  padding-top: 15px;
}

.payment-block #card-element {
  border-radius: 4px 4px 4px 4px;
}

.proceedPaymentBtn {
  font-size: 1.3em;
  margin-bottom: 10px;
  margin-top: 16px;
  grid-area: right;
}

.proceedPaymentBtn.proceedPaymentNewCardBtn {
  width: 35%;
}

@media (max-width: 991px) {
  .proceedPaymentBtn.proceedPaymentNewCardBtn {
    width: 100%;
  }
}

.cancelPaymentBtn {
  font-size: 1.3em;
  margin-bottom: 10px;
  margin-top: 16px;
  margin-left: 20px;
}

.coupon_applied {
  color: #46b7ee;
}

.chief_complaints_field textarea {
  min-height: 30px;
  height: 100px;
}

.chief_complaints_section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.chief_complaints_label {
  width: 35%;
}

.chief_complaints_field {
  width: 65%;
}

.booking-summary {
  margin-top: 12px;
  border-left: 1px solid #e4e4e4;
  padding-left: 25px;
  padding-right: 12px;
  height: 100%;
}

.booking-summary-block {
  margin-top: 2rem;
  border-top: 1px solid #e4e4e4;
  padding-top: 1rem;
}

.booking-title {
  font-size: 20px;
  color: #46b7ee;
  padding-top: 8px;
}
.coupon-code-label {
  padding-top: 8px;
}

.coupon-show-link {
  cursor: pointer;
  color: #46b7ee !important;
  display: block;
  text-align: right;
  padding-top: 12px;
}

.booking-date li.discount-color,
.booking-date li.discount-color span {
  color: #46b7ee;
}

.booking-details-title {
  font-size: 16px;
  margin-bottom: 20px;
}

.price-details-coupon {
  margin-top: 1rem;
}

.coupon-wrapper {
  /* border: 1px dashed #ccc; */
  padding: 0;
}

.coupon-btn .btn {
  height: 40px;
}

.coupon-wrapper .input-wrapper {
  border: none;
  padding: 0;
}

.coupon-wrapper .coupon-applied-wrapper {
  border: 1px dashed #ccc;
  position: relative;
  padding: 2px 12px 2px 0;
}

.coupon-label {
  position: absolute;
  left: 11px;
  background-color: #fff;
  font-size: 13px;
  top: -10px;
  color: #969696;
  padding: 0 4px;
}

.btn-wrapper.button-wrapper--remove {
  color: red;
  font-weight: 600;
  font-size: 13px;
  cursor: pointer;
}

.input-wrapper .coupon-input.form-control {
  border-radius: 0;
  margin-left: 0;
  height: 40px;
}

.coupon-wrapper .coupon-applied-wrapper .input-wrapper .coupon-input.form-control {
  border-color: transparent !important;
  background-color: transparent;
  font-weight: 700;
  color: #969696;
}

.coupon-applied-info {
  font-weight: 500;
  color: #588209;
  margin-bottom: 4px;
  margin-top: 6px;
  font-size: 14px;
}

.coupon-invalid-info {
  font-weight: 500;
  color: red;
  margin-bottom: 4px;
  margin-top: 6px;
  font-size: 14px;
}

.coupon-applied-info .list {
  display: block;
}

.discount-item-wrap {
  margin-top: 1rem;
}

.booking-date {
  padding: 0;
  list-style: none;
}

.booking-date textarea.form-control {
  min-height: 100px;
  width: 100%;
}

.booking-date li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
  margin-bottom: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.booking-date li span {
  color: #757575;
  font-weight: 400;
  font-size: 15px;
}

.booking-fee {
  padding: 0;
  list-style: none;
}

.booking-fee li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
  text-transform: capitalize;
  margin-bottom: 15px;
}

.booking-fee li span {
  float: right;
  color: #757575;
  font-weight: 400;
  font-size: 15px;
}

.booking-total {
  border: 1px solid #e4e4e4;
  border-top: 3px solid #e4e4e4;
  margin-top: 15px;
  padding: 15px;
}

.booking-total .booking-total-list {
  border-top: 2px solid #e4e4e4;
  padding-top: 1rem;
}

.booking-total ul {
  padding: 0;
  list-style: none;
  margin: 0;
}

.booking-total ul li span {
  font-size: 18px;
  font-weight: 600;
  color: #272b41;
}

.booking-total ul.booking-fee li span {
  font-weight: 400;
  font-size: 15px;
  color: #757575;
}

.booking-total ul li .total-cost {
  font-size: 16px;
  float: right;
}

.list-group-item {
  padding: 2px 12px;
  color: #969696;
}

.payment-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.reason-consultation {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.reason-label {
  padding-bottom: 8px;
  color: black !important;
  font-size: medium !important;
}

/** Payment Overlay **/
.payment-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: #fff;
}

.payment-wrapper.hide-loader {
  display: none;
}

.payment-rate {
  color: #2a8358;
}

.right-triangle {
  position: absolute;
  bottom: 0%;
  right: 0%;
  height: 100%;
  width: 63%;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(211, 211, 211, 0.5)), to(rgba(211, 211, 211, 0.3)));
  background: linear-gradient(rgba(211, 211, 211, 0.5), rgba(211, 211, 211, 0.3));
  -webkit-clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
}

.loader-wrapper {
  position: relative;
  height: 120px;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
            transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
            transform: translate(-50%, -50%) rotate(360deg);
  }
}

.container.payment-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  z-index: 1;
  -webkit-box-shadow: -1px 2px 11px -2px #383633;
  box-shadow: -1px 2px 7px -4px #383633;
  height: calc(100% - 20%);
  border-left: 3px solid #2a8358;
  border-radius: 5px;
}

.loader {
  width: 75px;
  height: 75px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: hsl(151deg 51% 34%);
  border-radius: 400px;
  -webkit-clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
  clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
  animation: rotate 1s infinite linear;
  transition: all 0.25s ease-in-out;
}
.loader:hover {
  background: red;
}
.loader:before {
  z-index: 1;
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: hsl(0deg 0% 100%);
  left: 6%;
  top: 1%;
}

@media (max-width: 967px) {
  .bookingSummary_docDetails_img > img {
    width: 75px;
    height: 75px;
  }
}

@media (max-width: 767px) {
  .bookingSummary_docDetails {
    text-align: center;
  }
  .chief_complaints_section {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .chief_complaints_label {
    margin-bottom: 8px;
  }
  .chief_complaints_label,
  .chief_complaints_field {
    width: 100%;
  }
  .booking-summary {
    margin-top: 12px;
    border-left: none;
    padding-left: 0;
    padding-right: 0;
    border-top: 1px solid #e4e4e4;
    padding-top: 12px;
  }
  .payment-block {
    margin-top: 27px;
    border-top: 3px solid #e4e4e4;
    padding-top: 1rem;
  }
  .container.payment-card {
    width: calc(100% - 10%);
  }
}

#chief_complaints.error, .input-wrapper .form-control.invalidCoupon {
  border-color: red !important;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.errorText {
  color: red;
}

@media screen and (max-width: 390px) {
  .booking-date li {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.agree_bookingSummary_wrap {
  font-size: 14px;
  font-weight: 500;
  color: #272b41;
}

.btn.disabled {
  cursor: not-allowed;
}

.agree_bookingSummary_element input,
.agree_bookingSummary_element label {
  cursor: pointer;
}

.agree_bookingSummary_wrap a {
  color: #46b7ee;
  text-decoration: none;
}

.deleteCard {
  width: 100%;
  height: 44px;
}

.payBtnCvc {
  width: 100%;
  height: 44px;
}

.cardImage {
  width: 40px;
  margin: 0px 20px 0 10px;
}

.new-card-list {
  display: inline-block !important;
}

.list-item-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
}

.cvcSection {
  padding: 12px 0px 20px 40px;
}

#cvcbox {
  height: 50px;
  width: 30%;
}

.payment-block-section {
  margin-bottom: 0%;
}

.cardImg {
  -ms-flex-preferred-size: 15%;
      flex-basis: 15%;
}

.cardName {
  -ms-flex-preferred-size: 55%;
      flex-basis: 55%;
  /* margin-left: 5%; */
}

.cardExp {
  -ms-flex-preferred-size: 25%;
      flex-basis: 25%;
}

.cardRadio {
  -ms-flex-preferred-size: 5%;
      flex-basis: 5%;
}

.cardDelete {
  -ms-flex-preferred-size: 5%;
      flex-basis: 5%;
  color: #fe5d69;
}

.pointer {
  cursor: pointer;
}

.section-title {
  font-size: 18px;
  margin-top: 20px;
}

.green-outline {
  margin: 0 0 10px 0;
}

@media screen and (max-width: 991px) {
  .cardName {
    -ms-flex-preferred-size: 45%;
        flex-basis: 45%;
    margin-top: -2%;
  }
  .cardExp {
    margin-top: -2%;
  }
  .cardImg {
    -ms-flex-preferred-size: 25%;
        flex-basis: 25%;
  }
  .gridAuto {
    grid-area: auto;
  }
}

.file-share-list {
  min-height: auto;
  width: 100%;
}

.react-dropzone-text {
  font-size: 16px;
  color: #6b757c;
  font-weight: 500;
}

.react-dropzone-list-item {
  background-color: #f5f5f5;
  margin: .7em;
  padding: .5em;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 2px 2px 5px -1px rgba(0, 0, 0, 0.18);
}

.react-dropzone-list-item p {
  margin-bottom: 0 !important;
}

.react-dropzone-error-text {
  color: #fe5d69;
  margin: 0 .5em;
}

.paypalSection {
  color: #fe5d69;
  font-weight: bold;
}

#paypal-button {
  text-align: center;
  margin-bottom: 15px;
}

.no-click {
  pointer-events: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.react-dropzone-remove-button-container {
  width: 100%;
  padding-right: .8em;
  color: #fe5d69;
}

.warning-label {
  display: block;
  text-align: right;
  color: #fe5d69;
  font-size: 0.8em;
  width: 100%;
  margin-top: 5px;
}

.margin-1em {
  margin: 1em;
}

.margin-bottom {
  margin-bottom: .5em;
}

.file-upload-text {
  font-weight: 550;
  color: #5F5F5F;
}

.card-info {
  color: black;
}

.consult-fee {
  color: black !important;
  font-size: large !important;
}

.appt-date-time {
  color: black !important;
  font-size: medium !important;
}

.price-details-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.price-details-container svg {
  margin: .8em .5em;
  cursor: pointer;
}

.price-details-container svg :hover {
  color: #00A1FA;
}