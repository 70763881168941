.email-verification-wrapper {
  padding: 1rem;
}

.email-verification-wrapper:before {
  width: 100%;
  height: 264px;
  position: absolute;
  top: 0;
  left: 0;
  background: #203A45;
  content: " ";
}

.verification-container {
  padding-top: 4rem;
  padding-left: 15%;
  padding-right: 15%;
}

.verification-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 4.375rem;
}

.verify-img-wrapper img {
  width: 230px;
}

.verify-img-wrapper {
  padding: 25px;
}

.verification-content {
  padding: 2rem 4rem;
}

/* .veri-btn-wrapper {
  text-align: center;
  margin-bottom: 2rem;
} */
