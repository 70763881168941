.index-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: rgb(17, 160, 204);
}
.para-header {
  font-weight: bold;
  font-size: 1em; 
  color: #333333;
}

.para-time {
  font-size: 1em; 
  color: #555555; 
  
}

.text-header {
  color: white;
}
.card-body {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  color: #333333;
}

.card-body p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.card-body ul {
  list-style-type: none;
  padding: 0;
}

.card-body li {
  margin-bottom: 10px;
}

.card-body strong {
  font-weight: bold;
  color: #007bff; /* or any other accent color */
}

/* Add hover effect for links inside the card-body */
.card-body a {
  color: #007bff;
  /* text-decoration: none; */
  transition: color 0.3s ease;
  text-decoration: underline;
}

.card-body a:hover {
  color: #0056b3;
}

/* Highlight Dr. James Carter */
/* .card-body .highlight-doctor {
  background-color: #2f94d8; 
  color:white;
  padding: 8px;
  border-radius: 4px;
  display: inline;
} */



.btns-title {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 339px);
}

.btns-title .btn {
  margin-bottom: 0;
}

.btns-title > a:first-child {
  margin-right: 12px;
}

@media screen and (max-width: 991px) {
  .index-header {
    flex-direction: column;
    width: 100%;
  }

  .btns-title {
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 448px) {
  .btns-title {
    flex-direction: column;
    align-items: center;
  }
  .btns-title .btn {
    margin-bottom: 12px;
    width: 200px;
  }
  .btns-title > a:first-child {
    margin-right: 7px;
  }
}