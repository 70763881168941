.close-term {
    background-color: white;
    color: #1b3b44;
    border-color: #00c789;
}

.close-term:hover {
    background-color: #00c789;
    color: white;
    border-color: #00c789;
}

.close-term-container {
    justify-content: center;
}

.terms-and-conditions li {
    display: block
}

.terms-and-conditions li:before {
    content: counters(item, ".") " ";
    counter-increment: item;
}

.terms-and-conditions ol {
    counter-reset: item;
}

.term-container-height {
    max-height: 75vh;
    overflow-y: scroll;
}