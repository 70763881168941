.login-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.image-wrapper {
  padding: 0 0 1rem 0;
}

.card-body {
  padding: 3.25rem;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
}

.btn {
  margin-bottom: 12px;
}

.button-link,
.button-link .btn,
.sign-up-btn {
  width: 100%;
}
.email-and-phone{
  color: grey;
}

.signin-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 1s ease forwards;
}

.visibility-hidden {
  visibility: hidden;
  animation: fadeOut 1s ease forwards;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.alert-fixed {
  width: 100%;
  position: fixed;
  z-index: 9;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.email-edit-icon{cursor: pointer;}
.inlined{display: inline-block;}
.collapse.show{
  transition: height 0.35s ease;
}

.custom-file-upload {
  background-color: #0798d1;
  color: #fff;
  border-style: 0.5 solid;
  border-radius: 5px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
  border-color: #22b8f3;

}
input.form-control-file{
  display: none;
}
.newto{
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}
.newto:before {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background: #46b7ee;
  left: 0;
  top: 50%;
  position: absolute;
}

.newto:after {
  content: "";
  display: block;
  width: 20%;
  height: 2px;
  background: #46b7ee;
  right: 0;
  top: 50%;
  position: absolute;
}

.terms-of-use {
  cursor: pointer;
  margin-top: 10px;
}

.doc-type:hover {
  color: #005485;
} 

.password-exclamation{
  display: contents;
}