@media(min-width:1025px){
.sidebar.toggled {
    margin-left: 0px;
    min-width: 75px;
    max-width: 75px;
}
.sidebar-brand img{
    width: auto;
    height: 43px;
}
.sidebar.toggled .version-number{
font-size: 9px;
}

.sidebar.toggled .sidebar-item span{
    display: none;
}
.sidebar.toggled .sidebar-nav{
    padding-top: 75px;
}
.sidebar.toggled .powered-by span{
    display: none;
}
.sidebar.toggled .sidebar-item{
    text-align: center;
}
.sidebar{
    transition: all 0.3s;
}
.sidebar .sidebar_title{
    position: absolute;
    color: rgb(255, 255, 255) !important;
    transition: margin-left 0.3s ease-in-out, left 0.3s ease-in-out, margin-right 0.3s ease-in-out, right 0.3s ease-in-out;
}
.toggled .new-icons{
    border-bottom: 1px solid rgba(255, 255, 255, 0) !important;
}
.new-icons{
    border-bottom: 1px solid rgba(255, 255, 255, 0.178) !important;
}
.sidebar-link:hover{
    background-color: transparent;
}
.sidebar .sidebar-titleSection{
    top: 30%;
    margin-left: 4%;
    /* font-size: 1rem; */
    
}
.sidebar.toggled .equipo-logo{
    width: 50%;
}
.sidebar.toggled .icon-logo{
    width: 80%;
}
}

.sub-nav-item-padding{
    z-index: 0;
}