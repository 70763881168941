.video-card-height {
    padding-top: 5px;
    min-height: 82vh;
}

.cheif-complaint {
    min-height: 15vh;
}

.call-timer {
    font-size: 1em;
    font-weight: bold;
    /* color: white; */
    float: right;
    
}
.video-timer-icon {
    margin: 0 0.7em;
    font-size: 1.2em;
}

.timer-separator {
    padding: 0 5px;
}

.video-physician-name {
    padding-right: 6px;
}

.video-physician-name::after {
    content: '/';
}

.no-attachment-card {
    height: 6em;
    justify-content: center;
    text-align: center;
    background: #F5F5F5;
}

.file-attachment-card {
    background: #F5F5F5;
    padding: 1em 1.5em 1em 1em;
}

.file-attachment-card-container {
    height: 73vh;
    overflow-y: scroll;
}

.attachment-tab-delete-icon {
    margin: 0 2px;
}

.pdf-viewer {
    width: auto;
    height: 70vh;
    overflow-y: scroll;
}

.image-viewer {
    width: 100%;
    height: auto;
}

.doc-viewer-blank-state {
    text-align: center;
    margin-top: 15vh;
    width: 100%;
}

.top-download-button {
    margin: auto;
}

.prescription-pdf-viewer-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }

  .doc-viewer-spinner {
      margin-top: 30vh !important;
  }

  .care-provider-image {
      margin: 1em;
  }

  .consult-care-provider-info-card {
      background-color: #F5F5F5;
  }
  .consult-care-provider-info {
      margin: 1em .5em 1em -1.5em;
  }

  .reason-for-consultation-container {
      margin: 0 1.25em 1.25em 1.25em;
  }


@media screen and (max-width: 767px) {
    .call-timer {
        margin-bottom: 12px;
    }
    .video-call-heading {
        display: flex;
        flex-direction: column;
    }
    .video-physician-name::after {
        content: '';
    }
}