.providerCard {
  padding: 0.75rem 1.25rem;
}
.profileCard {
  border: 1px solid rgba(0,0,0,.125);
}

.profileWrapper {
  display: flex;
  margin: 0 1em 0 0;
}

.imageWrapper {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.profileContent {
  width: calc(100% - 90px);
  display: flex;
  justify-content: space-between;
}

.profileContent.cCProfileContent {
  justify-content: flex-start;
  flex-direction: column;
}

.profileData {
  width: 100%;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cCBtnWrapper {
  justify-content: flex-start;
  padding-top: 12px;
}

.scheduleProfileDesig {
  padding-left: 6px;
}
.scheduleContent {
  display: flex;
}

.scheduleContentLabel {
  align-self: flex-end;
  color: #203A45;
  min-width: 115px;
  max-width: 100%;
  position: relative;
}

.scheduleContentLabel::after {
  content: ':';
  position: absolute;
  right: 0;
}

.scheduleContentValue {
  padding-left: 12px;
}


@media screen and (max-width: 768px) {
  .providerCard {
    padding: 0.625rem 0;
  }
  .profileContent {
    flex-direction: column;
  }
  .btnWrapper {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 500px) {
  .scheduleContent {
    flex-direction: column;
    margin-bottom: 8px;
  }

  .scheduleContentLabel {
    align-self: auto;
    font-weight: bold;
  }

  .scheduleContentValue {
    padding-left: 0;
  }

  .scheduleContentLabel::after {
    content: '';
  }
}

@media screen and (max-width: 425px) {
  .profileWrapper {
    display: flex;
    margin: 0 1em 0 0;
    flex-direction: column;
  }
}