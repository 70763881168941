.chat-bg {
  margin: 20px;
  border: 1px solid #cdcdcd;
  position: relative;
}

.chat-left-side {
  display: flex;
  align-items: center;
  background-color: rgba(243, 243, 243, 0.541);
  padding: 10px;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.chat-left-side img {
  width: 50px;
  border-radius: 50px;
}

.chat-right-side {
  overflow-y: scroll;
  height: 400px;
  background: #e5edf5;
}

.send-message-box {
  padding: 10px;
}

.enter-to-send {
  width: 95% !important;
  border: none;
  outline: none;
  padding: 0 10px;
}

.mic-and-send {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}

.input-mesage {
  width: 100%;
  border: 1px solid #bfbfbf;
  padding: 7px 20px;
  outline: none;

}

.chat-left-section {
  background-color: #fff;
  padding: 0;
}

.conversations-list {
  height: 436px;
  overflow-y: scroll;
}

.chat-left-side.active {
  background-color: #eee;
}

.name-section-left {
  padding: 15px;
  background: #ffffff;
  margin: 0;
}

.name-section {
  padding: 15px;
  background: #ffffff;
  margin: 0;
  position: sticky;
  top: 0;
}

.single-messages {
  display: flex;
  align-items: center;
  margin: 20px 10px 0px;
  width: 80%;
  float: left;
}

.single-messages p {
  background: #ffffffa6;
  padding: 15px 20px;
  border-radius: 10px 10px 10px 0;
  margin: 0 5px;
}

.single-messages-me {
  float: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.single-messages-me p {
  float: right;
  border-radius: 10px 10px 0 10px;
  background: #4c7b8e;
  color: #fff;
}

.single-messages img {
  width: 30px;
  object-fit: contain;
  border-radius: 50px;
}


/* width */
.chat-bg ::-webkit-scrollbar {
  width: 4px;
}

/* Track */
.chat-bg ::-webkit-scrollbar-track {
  background: #e7e7e7;
}

/* Handle */
.chat-bg ::-webkit-scrollbar-thumb {
  background: rgb(207, 207, 207);
}

/* Handle on hover */
.chat-bg ::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media(max-width: 768px) {
  .enter-to-send {
    width: 90% !important;
  }
}