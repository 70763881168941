.cardBody {
  padding: 0.75rem 1.25rem;
}

.securityStatusWrapper {
  display: flex;
}

.securityStatusLabel {
  font-weight: 600;
  padding-right:  1.375rem;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
}

.securityStatusValue {
  padding-left: 1.25rem;
}

.securityStatusDisabled {
  color: red;
}

.securityStatusEnabled {
  color: var(--green);
}

.securityStatusBtn {
  background: var(--green);
  background-color: var(--green);
  border-color: var(--green);
  padding: 0.25rem 0.7rem;
  border-radius: 0.2rem;
  color: var(--white)!important;
}

.securityStatusBtnSpan {
  margin-right: 0.5rem;
}

.securityStatusFooterModal {
  justify-content: center;
}

.securityStatusModalWarning {
  color: var(--red);
}