.otp-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    height: 100%;
   
  
}
.otp-text-wrapper {
    width:100% !important;
}



.otp-card-body {
  padding: 3.25rem;

}

.otp-input {
    width:500px;
}
.margin-top--small {
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.otp-img-fluid {
    max-width: 100%;
    height: auto; 
    max-width: 40vh;
}

.modal-otp-img-fluid {
    max-width: 100%;
    height: auto; 
    max-width: 35vh;
}

.two-step-toggle-description {
    position: relative;
    padding: 0.75rem 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    /* color: #495057; */
    background-color: #f1f1f1;
    border-color: #d6d8db;
  }

 
