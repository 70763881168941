.exclamation {
  color: #fba602;
  margin-right: 10px;
  font-size: 48px;
}

@media (max-width: 480px) {
  .exclamation {
    font-size: 32px;
  }
}

.profile-warning {
  color: #fba602;
  font-size: 28px;
  margin-top: 20px;
  margin-right: 10px;
}

.icon-container {
  display: flex;
  align-items: center;
}

.btn.btn-error {
  border: 1px solid red;
}

.mt-20 {
  margin-top: 20px;
}

.inline {
  display: inline;
}

.profile-content-wrapper::-webkit-scrollbar {
  display: none;
}

.react-select__control {
  box-shadow: none !important;
}

.link-sections__text {
  cursor: pointer;
}

.physician_details_modal {
  width: 70%;
  max-width: 70%;
}

.physician_details_modal .card {
  margin-bottom: 0px;
}

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: -3px;
  color: #46b7ee;
  z-index: 99;
  font-size: 24px;
}

.consent-modal-close {
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: -3px;
  color: #46b7ee;
  z-index: 99;
  font-size: 24px;
}

.modal-border {
  border-bottom: 1px solid #dee2e6;
}

.font-style {
  font-size: larger;
}

.ModalHeader {
  text-align: center;
  /*  margin-bottom: 15px; */

}

.search-wrapper {
  display: flex;
  border: 7px solid #ced4da;
  border-left: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
}

.date-btn-container {
  display: flex;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.datepicker-wrapper,
.input-wrapper,
.select-wrapper {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0;
  border-right: 0;
  padding: 0.125rem 0.125rem 0.125rem 0.875rem;
}

.input-wrapper {
  border-left: 1px solid #ced4da;
  cursor: pointer;
}

.datepicker-wrapper {
  border-right: 1px solid #ced4da;
  ;
}

.btn-wrapper {
  display: flex;
}

.btn-wrapper .btn {
  margin: 0 !important;
  border-radius: 0;
}

.datepicker-icon,
.input-icon {
  margin-right: 0.5rem;
}

.datepicker-wrapper .react-datepicker__input-container input,
.search-wrapper .input-wrapper .react-datepicker__input-container:focus input,
.search-wrapper .input-wrapper .form-control,
.search-wrapper .input-wrapper .form-control:focus,
.search-wrapper .react-select-container .react-select__control {
  border-color: transparent !important;
}

.schedule-row {
  align-items: center;
}

.rec-slider .rec {
  height: 100%;
}

.rec-slider.rec>div {
  outline: none;
}

.physician-list {
  height: 55vh;
}

.select-wrapper {
  position: relative;
}

.speciality-err {
  position: absolute;
  bottom: -38px;
  left: 19px;
  background: #f58a8a;
  color: #fff;
  z-index: 9;
  border-radius: 4px;
  padding: 4px;
}

.speciality-err:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f58a8a;
  position: absolute;
  top: -10px;
}

.profile-content-wrapper {
  overflow-y: scroll;
}

.day-slot {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.day-slot span {
  display: block;
  font-size: 18px;
  text-transform: uppercase;
}

.day-slot.normal-text span {
  font-weight: bold;
  font-size: 15px;
  color: black;
  padding-bottom: 5px;
  text-transform: none;
}

.day-slot span.slot-date {
  display: block;
  color: #757575;
  font-size: 14px;
}

.day-slot small.slot-year {
  color: #757575;
  font-size: 14px;
}

.custom-select.error {
  border: 2px solid red !important;
}

.arrow-btn,
.arrow-btn:focus {
  outline: none;
  background: transparent;
  border-color: transparent;
}

.day-slot li.left-arrow {
  left: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px !important;
  transform: translateY(-50%);
}

.day-slot li.right-arrow {
  right: -11px;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 20px !important;
  transform: translateY(-50%);
}

.slider-container {
  display: flex;
  justify-content: center;
}

.slide-wrap-vital-arrows {
  width: 95%;
}

.slide-wrap-vital {
  width: 100%;
}

.slide-wrap {
  width: 100%;
}

.hideBtn {
  opacity: 0;
}

.pat-time-row {
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  height: 172px;
  overflow: hidden;
}

.pat-vital-row {
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  overflow: hidden;
}

.pat-time-row.show-more {
  min-height: 172px;
  height: auto;
  overflow: auto;
}

.pat-time-slot {
  display: flex;
  flex-wrap: wrap;
  float: left;
  flex-direction: row;
  margin: 0;
  padding: 0;
  /* width: 165px; */
  width: 82.5px;
  justify-content: center;
}

.pat-vital-slot {
  display: flex;
  flex-wrap: wrap;
  float: left;
  flex-direction: row;
  margin: 0;
  padding: 0;
  /* width: 165px; */
  width: 150px;
  justify-content: center;
}


.pat-time-slot__items {
  margin: 2px;
  list-style: none;
  height: 30px;
  display: flex;
  cursor: pointer;
}

.profile-content-wrapper .rec-slider.rec>div {
  border-right: 1px solid #cccc;
}

/* .rec-slider.rec > div:nth-child(even) .pat-time-slot__item {
  background: #28a745;
} */

.pm-block .pat-time-slot__item {
  background: #28a745;
}

.pat-time-slot__item,
.pat-time-slot__item---empty {
  background: #ffa500;
  color: #fff;
  border-radius: 4px;
  padding: 5px;
  width: 73px;
}

.pat-time-slot__item-vital-data,
.pat-time-slot__item-vital-data---empty {
  background: white;
  color: #3e4676;
  border-radius: 4px;
  padding: 5px;
  /* width: 73px; */
  width: 100%;
  text-align: center;
}


.pat-time-slot__item-vital,
.pat-time-slot__item-vital---empty {
  /* background: #46b7ee; */
  /* color: #3e4676; */
  color: black;
  border-radius: 4px;
  padding: 5px;
  /* width: 150px; */
  width: 100%;
  font-weight: bold;
}


.pat-time-slot__item---empty {
  background-color: #ced4da;
  min-width: 62px;
  text-align: center;
}

.link-sections {
  display: block;
  margin-top: 1rem;
}

.link-wrapper {
  display: flex;
  align-items: center;
}

.link-sections__text {
  padding-left: 4px;
}

.profile-row {
  border-bottom: 1px solid#ced4da;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
}

.flex-change {
  flex-wrap: nowrap !important;
}

.show-more-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.show-more-btn {
  color: #46b7ee !important;
  cursor: pointer;
}

.react-select-container {
  width: 100%;
}

.divider-line {
  margin-top: 0;
}

.legend-row {
  display: flex;
}

.legend-label {
  font-weight: bold;
  color: #46b7ee;
  margin-right: 8px;
}

.legend-label::after {
  content: ':';
}

/** Skeliton Styles **/
.skeleton-wrapper {
  background: #fff;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  margin: 10px 15px;
}

.skeleton-wrapper-inner {
  height: 150px;
  padding: 15px;
  position: relative;
}

.skeleton-wrapper-body div {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeholderSkeleton;
  -webkit-animation-timing-function: linear;

  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-name: placeholderSkeleton;
  background: #f6f7f8;
  background-image: -webkit-gradient(linear, left center, right center, from(#f6f7f8), color-stop(.2, #edeef1), color-stop(.4, #f6f7f8), to(#f6f7f8));
  background-image: -webkit-linear-gradient(left, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

.skeleton-wrapper-body {
  -webkit-animation-name: skeletonAnimate;
  animation-name: skeletonAnimate;
  background-image: -webkit-gradient(linear, center top, center bottom, from(deg), color-stop(0, red), color-stop(.15, orange), color-stop(.3, yellow), color-stop(.45, green), color-stop(.6, blue), color-stop(.75, indigo), color-stop(.8, violet), to(red));
  background-image: -webkit-linear-gradient(135deg, red 0%, orange 15%, yellow 30%, green 45%, blue 60%, indigo 75%, violet 80%, red 100%);
  background-repeat: repeat;
  background-size: 50% auto;
}

.skeleton-wrapper-body div {
  position: absolute;
  right: 15px;
  left: 15px;
  top: 15px;
}

div.skeleton-avatar {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  right: auto;
}
div.skeleton-author {
  top: 20px;
  height: 20px;
  left: 95px;
  width: 150px;
}

div.skeleton-label {
  left: 95px;
  top: 50px;
  height: 10px;
  width: 100px;
}
div.skeleton-content-1,
div.skeleton-content-2,
div.skeleton-content-3 {
  left: 15px;
  right: 15px;
  height: 10px;
}
div.skeleton-content-1 {
  top: 100px;
}
div.skeleton-content-2 {
  top: 120px;
}
div.skeleton-content-3 {
  top: 140px;
}

@-webkit-keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

@-webkit-keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}

@keyframes skeletonAnimate {
  from {
    background-position: top left;
  }
  to {
    background-position: top right;
  }
}

@keyframes placeholderSkeleton {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

/** Skeliton Styles End **/



@media screen and (max-width: 768px) {
  .search-wrapper {
    flex-direction: column;
  }
}

@media screen and (max-width: 575px) {
  .physician_details_modal {
    margin: 1.75rem auto;
  }
  .physician_details_modal {
    width: 90%;
    max-width: 90%;
  }
  .legend-row {
    flex-direction: column;
  }

  .legend-label::after {
    content: '';
  }
}

@media screen and (max-width: 390px) {
  .react-datepicker-wrapper .react-datepicker__input-container input {
    width: 100% !important;
  }
  .profile-content-wrapper .rec-slider.rec > div {
    border-right-color: transparent;
  }

  .consultation-image {
    width: 60px;
    height: 60px;
  }

  .profile-wrapper {
    width: 58px!important;
    margin-right: 0.5rem!important;
  }

  .profile-content-section {
    width: calc(100% - 60px)!important;
  }

  .book-btn-wrapper .btn {
    padding: 4px !important;
  }
}

@media only screen and (min-width: 391px) and (max-width: 414px) {
  .date-btn-container .btn-wrapper {
    margin: 0 0 0px -24px;
  }

  .date-btn-container input {
    background: none !important;
  }
}




/* Vitals new left column sticky grid */
.vitals-grid-container {
  display: grid;
  /* This is a (hacky) way to make the .grid element size to fit its content */
  overflow: auto;
  width: 100%;
}
.vitals-grid {
  display: flex;
  flex-wrap: nowrap;
}
.vitals-grid-col {
  overflow: hidden;
  width: 101%;
  min-width: 125px;
  max-width: 200px;
}

.vitals-grid-item--header {
  height: 75px;
  min-height: 75px;
  position: sticky;
  position: -webkit-sticky;
  background: white;
  top: 0;
  padding-top: 5px;
}

.vitals-grid-col--fixed-left {
  position: sticky;
  left: 0;
  /* z-index: 9998; */
  z-index: 10;
  background: white;
}
.vitals-grid-col--fixed-right {
  position: sticky;
  right: 0;
  z-index: 9998;
  background: white;
}

.vitals-grid-item {
  height: 36px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.vitals-grid-col--fixed-left .vitals-grid-item {
  border-left-width: 0;
}

.detailsProfContentStarRating div{
  line-height: normal;
  display: inline-block;
  color: #fff;
  padding: 2px 4px 2px 6px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  vertical-align: middle;
  background-color: #388e3c;
  margin-left: 10px;
}

.detailsProfContentStarRating .iconLeftSpacing {
  margin-left: 5px !important;
}