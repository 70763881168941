.spinner-border {
    position: absolute;
    z-index: 99;
    /* top: 45%; */
    left: 48%;
}

.no-data-found {
    background: #e4f9f0;
    padding: 10px;
    border-radius: 5px;
    color: #2d9c6a;
}

.equipo-logo {
    margin: auto;
    width: 25%;
    margin-bottom: 1.5em;
}

.icon-logo {
    width: 16%;
}



.powered-by {
    font-size: 10px;
    margin: 0 0 0 7px;
    color: grey;
}