.chat-online {
  color: #34ce57;
}

.chat-offline {
  color: #e4606d;
}

.badge-success {
  background-color: #28a745;
}

.chat-box-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.attachment-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-bottom: 0.5rem;
  padding-right: 0.75rem;
}

.attachment-icon {
  color: #212529;
}
