.cardBody {
  padding: 0.75rem 1.25rem;
}

.currentEmail {
  color: #212529;
  margin-right: 10px;
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 768px) {
  .cardBody {
    padding: 0px 1.25rem!important;
  }

}

.password_tooltip {
  margin-left: 110% !important;
  margin-top: -18% !important;
  display: flex !important;
}

.center_save{
  margin-left: 500px;
}

.spacing {
  margin-right: 10px;
}


