.video-btn .btn {
    border-radius: 50%;
    height: 60px;
    width: 60px;
   }

.video-container {
    position: relative;
}

.permission-banner {
    background-color: #F26666;
    color: #fff;
    top: 0;
    position: absolute;
    width: 98%;
    border-radius: 4px;
    padding: 18px;
    text-align: center;
    left: 4px;
}

.modal-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
      
}
.highlight {
    font-weight: bold;
    color: #46b7ee;
}

.check-icon {
    color: #46b7ee;
    font-size: 72px;
}

.title {
    color: #46b7ee;
    font-size: 20px;
    font-weight: bold;
    margin: 20px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.pharmacy_info_section{
    margin-top: 20px;
    padding: 20px;
    border: 1px solid #3e4676;
}

.incomplete-profile-heading {
    font-size: 24px;
    font-weight: bold;
}

.bookingSummary_docDetails_wrap {
    padding: 0 0 1rem 0;
  }