.providerCard {
  padding: 0.75rem 1.25rem;
}

.profileWrapper {
  display: flex;
  margin-top: 2rem;
}

.imageWrapper {
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.profileContent {
  width: calc(100% - 90px);
  display: flex;
  justify-content: space-between;
}

.profileData {
  width: 100%;
}

@media screen and (max-width: 390px) {
  .providerCard .bookingCardBody {
    padding: 0;
  }
}